@import 'kfp-icon';

@import 'kfp-custom';

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

$main-color: #002b4f;

i.fas,
i.far,
i.fal,
i.fad {
    font-family: 'Font Awesome 5 Pro' !important;
    text-align: center;
}

.mat-icon {
    color: $main-color !important;
}

body {
    --fuse-primary-500-rgb: $main-color !important;
    --fuse-primary-rgb: $main-color !important;
    --fuse-text-default-rgb: $main-color !important;
    --fuse-primary-600-rgb: $main-color !important;
}

.color-white {
    color: white;
}

.bg-white {
    background-color: white;
}

.app-bg {
    background: rgb(242, 242, 246);
    background: linear-gradient(180deg, rgba(242, 242, 246, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.tile-bg {
    background-color: #edeef3;
}

.mat-expansion-panel-content > .mat-expansion-panel-body {
    padding: 0 !important;
}

.theme-default {
    --fuse-primary: $main-color;
    --fuse-on-primary: #ffffff;
}

.fixed-w-250 {
    width: 250px;
}

.fixed-w-200 {
    width: 200px;
}

.fixed-w-150 {
    width: 150px;
}

.red-text {
    color: #ff0000 !important;
}

.bg-disabled {
    background-color: #f5f5f5 !important;
}

.mat-custom-primary {
    background-color: $main-color !important;
    color: white !important;
}

.mat-custom-primary:disabled {
    background-color: $main-color !important;
    opacity: 0.5;
    color: white !important;
}

.mat-custom-dark {
    background-color: $main-color !important;
    color: white !important;
}

.mat-custom-dark > .mat-icon {
    color: white !important;
}

.mat-custom-dark-outline {
    border-width: 4px;
    border-color: $main-color !important;
    color: $main-color !important;
}

.mat-custom-dark-outline > .mat-icon {
    color: $main-color !important;
}

.mat-mdc-outlined-button:not([disabled='true']) {
    border-color: $main-color !important;
}

.mat-custom-primary > .mdc-button__label > i {
    color: white !important;
}

.mat-custom-primary > .mdc-button__label > span {
    color: white !important;
}

.mat-custom-primary:disabled {
    background-color: $main-color !important;
    color: white !important;
}

.mat-custom-primary:disabled > .mdc-button__label {
    color: white !important;
}

.mat-custom-primary:disabled > .mdc-button__label > i {
    color: white !important;
}

.mat-custom-primary:disabled > .mdc-button__label > span {
    color: white !important;
}

.fixed-w-110 {
    min-width: 110px !important;
}

.color-primary {
    color: var(--primary) !important;
}

.border-primary {
    border-color: var(--primary) !important;
}

.color-primary2 {
    color: var(--primary2) !important;
}

button.color-primary > span {
    border-color: var(--primary) !important;
}

.mat-mdc-form-field {
    position: relative;
    z-index: 5;
}

.phone-input {
    z-index: 10 !important;

    .mat-mdc-form-field-subscript-wrapper {
        z-index: -1 !important;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.menu-icon {
    margin-left: 10px;
    color: $main-color;
    cursor: pointer;
}

.filled-notice {
    color: limegreen !important;
    position: relative;
}

.filled-notice::after {
    content: '\1F4AC';
    position: absolute;
    border-radius: 5px;
    top: 0;
    right: 0;
    font-size: 14px;
}

.nowrap {
    text-wrap: nowrap;
}

.parameter-input {
    color: #8e8e93 !important;
    font-size: 14px !important;
}

.compare .mat-mdc-dialog-surface {
    padding: 10px !important;
}

.phone-input {
    div.tel-form {
        background-color: white !important;
    }

    mat-form-field.prefix-form-field {
        min-width: 120px !important;
    }

    .mat-mdc-form-field-infix {
        padding-top: 0px !important;
    }

    mat-icon {
        display: none !important;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.custom-field .mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: 12px !important;
}

//APEX CUSTOMIZATION

.apexcharts-zoom-icon {
    visibility: hidden;
}

//MATERIALS

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
    background: darkgreen;
    border-radius: 50%;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
    background: darkred;
    border-radius: 50%;
}

.custom-slider-div {
    .mat-mdc-slider .mdc-slider__tick-mark--active:nth-child(12n + 1),
    .mat-mdc-slider .mdc-slider__tick-mark--inactive:nth-child(12n + 1) {
        height: 10px;
        width: 10px;
        background-color: $main-color;

        opacity: 80;
    }
}
