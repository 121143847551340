@font-face {
    font-family: 'icomoon';
    src: url('../assets/fonts/icomoon.eot?9akel6');
    src: url('../assets/fonts/icomoon.eot?9akel6#iefix') format('embedded-opentype'),
        url('../assets/fonts/icomoon.ttf?9akel6') format('truetype'), url('../assets/fonts/icomoon.woff?9akel6') format('woff'),
        url('../assets/fonts/icomoon.svg?9akel6#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='kfpi-'],
[class*=' kfpi-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.kfpi-calculation-history:before {
    content: '\e91e';
}
.kfpi-calculation-probability:before {
    content: '\e91f';
}
.kfpi-calculation-profit:before {
    content: '\e921';
}
.kfpi-calculation-fin_math:before {
    content: '\e920';
}
.kfpi-calculation-mortgage:before {
    content: '\e920';
}
.kfpi-filled:before {
    content: '\e900';
}
.kfpi-unfilled:before {
    content: '\e901';
}
.kfpi-arrow-collapse:before {
    content: '\e902';
}
.kfpi-arrow-left:before {
    content: '\e903';
}
.kfpi-arrow-right:before {
    content: '\e904';
}
.kfpi-cancel:before {
    content: '\e905';
}
.kfpi-currency-czk:before {
    content: '\e906';
}
.kfpi-delete:before {
    content: '\e907';
}
.kfpi-arrow-double:before {
    content: '\e908';
}
.kfpi-flag:before {
    content: '\e909';
}
.kfpi-info:before {
    content: '\e90a';
}
.kfpi-menu-1:before {
    content: '\e90b';
}
.kfpi-pdf:before {
    content: '\e90c';
}
.kfpi-percentage:before {
    content: '\e90d';
}
.kfpi-subscription:before {
    content: '\e90e';
}
.kfpi-overview:before {
    content: '\e90f';
}
.kfpi-projects:before {
    content: '\e910';
}
.kfpi-search:before {
    content: '\e911';
}
.kfpi-account:before {
    content: '\e912';
}
.kfpi-add:before {
    content: '\e913';
}
.kfpi-add-1:before {
    content: '\e914';
}
.kfpi-download:before {
    content: '\e915';
}
.kfpi-hand:before {
    content: '\e916';
}
.kfpi-home:before {
    content: '\e917';
}
.kfpi-menu:before {
    content: '\e918';
}
.kfpi-menu-2:before {
    content: '\e919';
}
.kfpi-question:before {
    content: '\e91a';
}
.kfpi-reduce:before {
    content: '\e91b';
}
.kfpi-reduce-1:before {
    content: '\e91c';
}
.kfpi-zoom:before {
    content: '\e91d';
}
.kfpi-notice:before {
    content: '\270E';
}
