.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper{
    border: 1px solid #e5e5ea !important;
}

.vertical-text {
    -webkit-writing-mode: vertical-lr;
    /* old Win safari */
    writing-mode: vertical-lr;
    writing-mode: tb-lr;
    transform: rotate(180deg);
}

.animate-margin-left {
    transition: margin-left 0.3s ease; /* Adjust the duration and easing as needed */
}

.apexcharts-legend-marker{
    top: 0px !important;
}

.mat-expansion-panel {
    box-shadow: none;
}

:host ::ng-deep .mat-expansion-panel {
    box-shadow: none;
  }
